import { PageProps } from 'gatsby';
import React from 'react';
import { ErrorType, UPDATE_ERROR } from 'state/error/actions';
import useDispatch from 'state/useDispatch';
import { getQueryParam } from './getQueryParam';

export type PolicyNumberProps = {
  policyNumber: string;
};

const withPolicyNumber = <T,>(
  Component: React.FC<T & PolicyNumberProps>
): React.FC<T & PageProps> => (props) => {
  const { location } = props;

  const policyNumber = getQueryParam(location, 'policyNumber');
  const dispatchError = useDispatch();

  if (!policyNumber) {
    dispatchError({
      type: UPDATE_ERROR,
      errorType: ErrorType.NOT_FOUND,
    });
    return null;
  }

  return <Component {...props} policyNumber={policyNumber} />;
};

export default withPolicyNumber;
