import { applyRuleIf } from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRule } from '@rsa-digital/evo-shared-components/helpers/forms/types';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const isCurrentDateValidationDisabled = (): boolean =>
  (activeEnv === 'test' && typeof window !== 'undefined' && window.Cypress
    ? window.Cypress.env('GATSBY_DISABLE_CURRENT_DATE_VALIDATION')
    : process.env.GATSBY_DISABLE_CURRENT_DATE_VALIDATION) === 'true';

export const applyRuleIfCurrentDateValidationEnabled = <TValue, TData>(
  rule: ValidationRule<TValue, TData>
): ValidationRule<TValue, TData> =>
  applyRuleIf(() => !isCurrentDateValidationDisabled(), rule);
