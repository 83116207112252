import { BreakdownCoverLevel, Preferences, Renewal } from 'state/quote/quote';
import apiService from './apiService';
import { PaymentOption, RenewalQuoteRequest } from './quote/quoteRequest';
import { UpdateTierRequest } from './quoteClient';

type RenewalClient = {
  getRenewal: (policyNumber: string) => Promise<Renewal>;
  updateExcess: (policyNumber: string, excess: number) => Promise<Renewal>;
  updateCoverages: (
    policyNumber: string,
    coverages: { type: string; isSelected: boolean }[]
  ) => Promise<Renewal>;
  updateBreakdownCover: (
    policyNumber: string,
    isSelected: boolean,
    coverLevel?: BreakdownCoverLevel
  ) => Promise<Renewal>;
  updateTier: (policyNumber: string, update: UpdateTierRequest) => Promise<Renewal>;
  updatePaymentOption: (
    policyNumber: string,
    paymentOption: PaymentOption
  ) => Promise<Renewal>;
  updatePreferences: (policyNumber: string, preferences: Preferences) => Promise<Renewal>;
  startRenewal: (policyNumber: string) => Promise<Renewal>;
  updateRenewal: (policyNumber: string, data: RenewalQuoteRequest) => Promise<Renewal>;
};

const renewalClient: RenewalClient = {
  getRenewal: (policyNumber) => apiService.get<Renewal>(`policy/${policyNumber}/renewal`),
  updateExcess: (policyNumber: string, excess: number) =>
    apiService.put<Renewal>(`policy/${policyNumber}/renewal/excess`, {
      value: excess,
    }),
  updateCoverages: (
    policyNumber: string,
    coverages: { type: string; isSelected: boolean }[]
  ) => apiService.put<Renewal>(`policy/${policyNumber}/renewal/coverages`, coverages),
  updateBreakdownCover: (
    policyNumber: string,
    isSelected: boolean,
    coverLevel?: BreakdownCoverLevel
  ) =>
    apiService.put<Renewal>(`policy/${policyNumber}/renewal/breakdown`, {
      isSelected,
      coverLevel,
    }),
  updateTier: (policyNumber, update) =>
    apiService.put<Renewal>(`policy/${policyNumber}/renewal/tier`, update),
  updatePaymentOption: (policyNumber: string, paymentOption: PaymentOption) =>
    apiService.put<Renewal>(`policy/${policyNumber}/renewal/paymentpreferences`, {
      paymentOption,
    }),
  updatePreferences: (policyNumber: string, preferences: Preferences) =>
    apiService.put<Renewal>(`policy/${policyNumber}/renewal/preferences`, preferences),
  startRenewal: async (policyNumber: string) =>
    apiService.put(`policy/${policyNumber}/renewal/create`, {}),
  updateRenewal: async (policyNumber: string, data: RenewalQuoteRequest) =>
    apiService.put(`policy/${policyNumber}/renewal`, data),
};

export default renewalClient;
