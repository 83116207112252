import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import {
  ValidationRule,
  ValidationRules,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import {
  AdditionalDriver,
  AdditionalDriversDetails,
} from 'state/formData/additionalDriversDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';
import useAdditionalDriverDetailsValidationRules from './AdditionalDriver/validation';

type CsAdditionalDriversErrorMessages = {
  csAdditionalDriversSummary: {
    add_additional_drivers: CsErrorsMissingOnly;
    main_driver: CsErrorsMissingOnly;
  };
  csAdditionalDriversDriverDetails: {
    driver_title: CsErrorsMissingOnly;
    gender: CsErrorsMissingOnly;
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        date_too_early: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
      };
    };
    marital_status: CsErrorsMissingOnly;
    relationship_to_policy_holder: CsErrorsMissingOnly;
    uk_since_birth: CsErrorsMissingOnly;
    uk_resident_date: {
      error_messages: {
        missing: string;
        date_in_future: string;
        date_too_early: string;
        less_than_one_year_ago: string;
        before_date_of_birth: string;
        invalid_month: string;
        year_too_short: string;
      };
    };
  };
  csAdditionalDriversLicence: {
    licence_type: CsErrorsMissingOnly;
    other_licence_type: CsErrorsMissingOnly;
    licence_length_years: {
      error_messages: {
        missing: string;
        age_does_not_match: string;
      };
    };
    licence_length_months: {
      error_messages: {
        missing: string;
        age_does_not_match: string;
      };
    };
    driving_licence_number: {
      error_messages: {
        too_short: string;
        invalid_format: string;
      };
    };
  };
  csAdditionalDriversOther: {
    has_notifiable_conditions: CsErrorsMissingOnly;
    conditions_notified: CsErrorsMissingOnly;
    car_use: CsErrorsMissingOnly;
    other_vehicle_use: CsErrorsMissingOnly;
    refused_insurance: CsErrorsMissingOnly;
  };
  csAdditionalDriversOccupation: {
    employment_status: {
      error_messages: {
        missing: string;
        not_employed_and_business_use: string;
      };
    };
    main_occupation: CsErrorsMissingOnly;
    main_occupation_industry: CsErrorsMissingOnly;
    has_second_occupation: CsErrorsMissingOnly;
    secondary_occupation: CsErrorsMissingOnly;
    secondary_occupation_industry: CsErrorsMissingOnly;
  };
  csAdditionalDriversClaims: {
    motoring_accidents: CsErrorsMissingOnly;
    claim_date: {
      error_messages: {
        missing: string;
        not_in_last_five_years: string;
        date_in_future: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
      };
    };
    claim_type: CsErrorsMissingOnly;
  };
  csAdditionalDriversConvictions: {
    motoring_convictions: CsErrorsMissingOnly;
    conviction_date: {
      error_messages: {
        missing: string;
        not_in_last_five_years: string;
        date_in_future: string;
        invalid_day: string;
        invalid_month: string;
        year_too_short: string;
        invalid_date: string;
      };
    };
    pending_conviction: CsErrorsMissingOnly;
    offence_type: CsErrorsMissingOnly;
    penalty_points: CsErrorsMissingOnly;
    length_of_ban_months: CsErrorsMissingOnly;
    unspent_non_motoring_convictions: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csAdditionalDriversSummary {
      add_additional_drivers {
        error_messages {
          missing
        }
      }
      main_driver {
        error_messages {
          missing
        }
      }
    }
    csAdditionalDriversDriverDetails {
      driver_title {
        error_messages {
          missing
        }
      }
      gender {
        error_messages {
          missing
        }
      }
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          date_too_early
          too_young
          invalid_day
          invalid_month
          year_too_short
          invalid_date
        }
      }
      marital_status {
        error_messages {
          missing
        }
      }
      relationship_to_policy_holder {
        error_messages {
          missing
        }
      }
      uk_since_birth {
        error_messages {
          missing
        }
      }
      uk_resident_date {
        error_messages {
          missing
          date_in_future
          date_too_early
          less_than_one_year_ago
          before_date_of_birth
          invalid_month
          year_too_short
        }
      }
    }
    csAdditionalDriversLicence {
      licence_type {
        error_messages {
          missing
        }
      }
      other_licence_type {
        error_messages {
          missing
        }
      }
      licence_length_years {
        error_messages {
          missing
          age_does_not_match
        }
      }
      licence_length_months {
        error_messages {
          missing
          age_does_not_match
        }
      }
      driving_licence_number {
        error_messages {
          too_short
          invalid_format
        }
      }
    }
    csAdditionalDriversOther {
      has_notifiable_conditions {
        error_messages {
          missing
        }
      }
      conditions_notified {
        error_messages {
          missing
        }
      }
      car_use {
        error_messages {
          missing
        }
      }
      other_vehicle_use {
        error_messages {
          missing
        }
      }
      refused_insurance {
        error_messages {
          missing
        }
      }
    }
    csAdditionalDriversOccupation {
      employment_status {
        error_messages {
          missing
          not_employed_and_business_use
        }
      }
      main_occupation {
        error_messages {
          missing
        }
      }
      main_occupation_industry {
        error_messages {
          missing
        }
      }
      has_second_occupation {
        error_messages {
          missing
        }
      }
      secondary_occupation {
        error_messages {
          missing
        }
      }
      secondary_occupation_industry {
        error_messages {
          missing
        }
      }
    }
    csAdditionalDriversClaims {
      motoring_accidents {
        error_messages {
          missing
        }
      }
      claim_date {
        error_messages {
          missing
          not_in_last_five_years
          date_in_future
          invalid_day
          invalid_month
          year_too_short
          invalid_date
        }
      }
      claim_type {
        error_messages {
          missing
        }
      }
    }
    csAdditionalDriversConvictions {
      motoring_convictions {
        error_messages {
          missing
        }
      }
      conviction_date {
        error_messages {
          missing
          not_in_last_five_years
          date_in_future
          invalid_day
          invalid_month
          year_too_short
          invalid_date
        }
      }
      pending_conviction {
        error_messages {
          missing
        }
      }
      offence_type {
        error_messages {
          missing
        }
      }
      penalty_points {
        error_messages {
          missing
        }
      }
      length_of_ban_months {
        error_messages {
          missing
        }
      }
      unspent_non_motoring_convictions {
        error_messages {
          missing
        }
      }
    }
  }
`;

const additionalDriversWanted = (data: AdditionalDriversDetails): boolean => {
  return data.addAdditionalDrivers === true;
};

const processDriverRules = <T>(
  rules?: ValidationRule<T, AdditionalDriver>[]
): ValidationRule<T, AdditionalDriversDetails>[] | undefined =>
  rules?.map(({ validityCondition, ...rest }) => ({
    ...rest,
    validityCondition: (v, data, index) =>
      !additionalDriversWanted(data) ||
      validityCondition(v, data.additionalDrivers[index]),
  }));

const useAdditionalDriversRules = (): ValidationRules<AdditionalDriversDetails> => {
  const errorMessages = useStaticQuery<CsAdditionalDriversErrorMessages>(query);

  const driverRules = useAdditionalDriverDetailsValidationRules();

  return {
    addAdditionalDrivers: [
      required(
        errorMessages.csAdditionalDriversSummary.add_additional_drivers.error_messages
          .missing
      ),
    ],
    additionalDrivers: {
      title: processDriverRules(driverRules.title),
      gender: processDriverRules(driverRules.gender),
      firstName: processDriverRules(driverRules.firstName),
      lastName: processDriverRules(driverRules.lastName),
      dateOfBirth: processDriverRules(driverRules.dateOfBirth),
      maritalStatus: processDriverRules(driverRules.maritalStatus),
      relationshipToPolicyHolder: processDriverRules(
        driverRules.relationshipToPolicyHolder
      ),
      hasLivedInUkSinceBirth: processDriverRules(driverRules.hasLivedInUkSinceBirth),
      ukResidentSince: processDriverRules(driverRules.ukResidentSince),
      licenceType: processDriverRules(driverRules.licenceType),
      otherLicenceType: processDriverRules(driverRules.otherLicenceType),
      licenceHeldYears: processDriverRules(driverRules.licenceHeldYears),
      licenceHeldMonths: processDriverRules(driverRules.licenceHeldMonths),
      licenceNumber: processDriverRules(driverRules.licenceNumber),
      hasMedicalConditionsOrDisabilities: processDriverRules(
        driverRules.hasMedicalConditionsOrDisabilities
      ),
      isMedicalConditionsNotified: processDriverRules(
        driverRules.isMedicalConditionsNotified
      ),
      carUse: processDriverRules(driverRules.carUse),
      useOfOtherVehicles: processDriverRules(driverRules.useOfOtherVehicles),
      employmentStatus: processDriverRules(driverRules.employmentStatus),
      primaryOccupation: processDriverRules(driverRules.primaryOccupation),
      primaryOccupationIndustry: processDriverRules(
        driverRules.primaryOccupationIndustry
      ),
      hasSecondaryOccupation: processDriverRules(driverRules.hasSecondaryOccupation),
      secondaryOccupation: processDriverRules(driverRules.secondaryOccupation),
      secondaryOccupationIndustry: processDriverRules(
        driverRules.secondaryOccupationIndustry
      ),
      hasMotoringAccidents: processDriverRules(driverRules.hasMotoringAccidents),
      claims: {
        claimDate: processDriverRules(driverRules.claims?.claimDate),
        claimType: processDriverRules(driverRules.claims?.claimType),
      },
      hasMotoringConvictions: processDriverRules(driverRules.hasMotoringConvictions),
      convictions: {
        convictionDate: processDriverRules(driverRules.convictions?.convictionDate),
        pendingConviction: processDriverRules(driverRules.convictions?.pendingConviction),
        offenceType: processDriverRules(driverRules.convictions?.offenceType),
        penaltyPoints: processDriverRules(driverRules.convictions?.penaltyPoints),
        lengthOfBan: processDriverRules(driverRules.convictions?.lengthOfBan),
      },
      hasUnspentNonMotoringConvictions: processDriverRules(
        driverRules.hasUnspentNonMotoringConvictions
      ),
      hasRefusedInsurance: processDriverRules(driverRules.hasRefusedInsurance),
    },
    mainDriver: validateIf(additionalDriversWanted, [
      required(
        errorMessages.csAdditionalDriversSummary.main_driver.error_messages.missing
      ),
    ]),
  };
};

export default useAdditionalDriversRules;
