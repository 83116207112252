import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import policyClient from 'api/policyClient';
import renewalClient from 'api/renewalClient';
import React, { useEffect } from 'react';
import { SetupMigratedFieldsForRenewal } from 'helpers/migratedPolicyHelpers';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { useAdditionalDriversDetails } from 'state/formData/additionalDriversDetails';
import { UPDATE_POLICY, usePolicyData } from 'state/policy/policy';
import { isRenewal, UPDATE_RENEWAL, useQuote } from 'state/quote/quote';
import useDispatch from 'state/useDispatch';

type LoadRenewalWrapperProps = {
  policyNumber: string;
  alwaysLoad?: boolean;
};

const LoadRenewalWrapper: React.FC<LoadRenewalWrapperProps> = ({
  policyNumber,
  children,
}) => {
  const quote = useQuote();
  const policy = usePolicyData();
  const { isLoading, requestHandler } = useApiRequestHandler();
  const [additionalDriverDetails] = useAdditionalDriversDetails();

  const dispatch = useDispatch();

  const isRenewalLoaded =
    quote && isRenewal(quote) && quote.policyNumber === policyNumber;
  useEffect(() => {
    if (!isRenewalLoaded && !isLoading) {
      requestHandler(() =>
        renewalClient.getRenewal(policyNumber).then((quoteResponse) => {
          dispatch({ type: UPDATE_RENEWAL, quote: quoteResponse });
        })
      );
    }
    if (!policy) {
      // the policy needs to be in state for assumed data logic to work
      requestHandler(() =>
        policyClient.getPolicy(policyNumber).then((policyData) => {
          dispatch({ type: UPDATE_POLICY, policyData });
        })
      );
    }
    if (policy?.isMigrated) {
      SetupMigratedFieldsForRenewal(policy, additionalDriverDetails, dispatch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyNumber, requestHandler, dispatch, isRenewalLoaded, policy]);

  return !isRenewalLoaded || isLoading ? (
    <LoadingOverlay loadingMessage="Loading quote, please wait" />
  ) : (
    <>{children}</>
  );
};

export default LoadRenewalWrapper;
