import { useEffect, useReducer } from 'react';

export const useSingleEffectOnceConditionTrue = (
  callback: () => void,
  condition: boolean
): void => {
  const [hasCallbackRun, setCallbackRun] = useReducer(() => true, false);

  useEffect(() => {
    if (condition && !hasCallbackRun) {
      callback();
      setCallbackRun();
    }
  }, [callback, condition, hasCallbackRun]);
};
