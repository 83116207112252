import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import SelectInput, {
  Option,
} from '@rsa-digital/evo-shared-components/components/Form/SelectInput';
import InfoBanner from '@rsa-digital/evo-shared-components/components/InfoBanner';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { buildPartialNestedFieldId } from '@rsa-digital/evo-shared-components/helpers/forms/buildNestedFieldId';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import FormFooter from 'components/FormFooter';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';
import {
  trackButtonClick,
  trackDropdownSelect,
  trackFieldError,
  trackModalOpen,
  trackSelectOrTypeaheadFocus,
} from 'helpers/eventTracking';
import { scrollAndTrackError } from 'helpers/forms';
import { addItem, removeItem, updateItem } from 'helpers/repeatableForm';
import { useSingleEffectOnceConditionTrue } from 'helpers/useSingleEffectOnceConditionTrue';
import {
  AdditionalDriversDetails,
  AdditionalDriverWithKey,
  initialAdditionalDriver,
  MAIN_DRIVER_POLICY_HOLDER_VALUE,
  useAdditionalDriversDetails,
} from 'state/formData/additionalDriversDetails';
import { useDriverDetails } from 'state/formData/driverDetails';
import { useQuote } from 'state/quote/quote';
import AdditionalDriverForm from './AdditionalDriver';
import useAdditionalDriversQuestions from './questions';
import { InfoBannerHeading } from './styles';
import useAdditionalDriversRules from './validation';

export type AdditionalDriversFormProps = {
  moveBack?: () => void;
  moveNextText: string;
  moveNext: () => void;
  infoBanner: { heading: string; body: string };
};

const AdditionalDriversForm: React.FC<AdditionalDriversFormProps> = ({
  infoBanner,
  moveBack,
  moveNext,
  moveNextText,
}) => {
  const [
    additionalDriversDetails,
    updateAdditionalDriversDetails,
  ] = useAdditionalDriversDetails();

  const questions = useAdditionalDriversQuestions();
  const rules = useAdditionalDriversRules();

  const [policyHolderDetails] = useDriverDetails();

  const {
    getError,
    validateOnSubmit,
    showValidation,
    showAllValidationErrors,
  } = useValidation(additionalDriversDetails, rules, trackFieldError);

  const quote = useQuote();

  useSingleEffectOnceConditionTrue((): void => {
    showAllValidationErrors();
  }, !!quote);

  const getAdditionalDriverIdPrefix = (index: number): string =>
    buildPartialNestedFieldId('additionalDrivers', index);

  const getMainDriverOptions = (): Option[] => {
    const options = [];
    options.push({
      name: `${policyHolderDetails.firstName} ${policyHolderDetails.lastName}`,
      value: MAIN_DRIVER_POLICY_HOLDER_VALUE,
    });

    additionalDriversDetails.additionalDrivers.forEach((driver) => {
      if (driver.firstName || driver.lastName) {
        options.push({
          name: `${driver.firstName} ${driver.lastName}`.trim(),
          value: driver.key.toString(),
        });
      }
    });

    return options;
  };

  return (
    <form
      onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}
      id="additional-drivers-form">
      <QuestionField
        question={questions.addAdditionalDrivers}
        errorText={getError('addAdditionalDrivers')}>
        <BooleanRadioInput
          id="addAdditionalDrivers"
          value={additionalDriversDetails.addAdditionalDrivers}
          onChange={(newValue) => {
            const update: Partial<AdditionalDriversDetails> = {
              addAdditionalDrivers: newValue,
            };
            if (newValue && additionalDriversDetails.additionalDrivers.length === 0) {
              update.additionalDrivers = addItem<AdditionalDriverWithKey>(
                [],
                initialAdditionalDriver
              );
            }
            updateAdditionalDriversDetails(update);
          }}
        />
      </QuestionField>
      {additionalDriversDetails.addAdditionalDrivers && (
        <RepeatableSection
          onAdd={() => {
            trackButtonClick('addButton', 'additionalDrivers');
            updateAdditionalDriversDetails({
              additionalDrivers: addItem(
                additionalDriversDetails.additionalDrivers,
                initialAdditionalDriver
              ),
            });
          }}
          onRemove={(index) => {
            const currentDrivers = additionalDriversDetails.additionalDrivers;
            const updatedDrivers = removeItem(currentDrivers, index);
            const update: Partial<AdditionalDriversDetails> = {
              additionalDrivers: updatedDrivers,
              addAdditionalDrivers: updatedDrivers.length >= 1,
            };
            if (additionalDriversDetails.mainDriver === currentDrivers[index].key) {
              update.mainDriver = undefined;
            }
            updateAdditionalDriversDetails(update);
          }}
          disableAdd={additionalDriversDetails.additionalDrivers.length >= 4}
          label={questions.additionalDrivers.label}
          addText={questions.additionalDrivers.addText}
          removeText={questions.additionalDrivers.removeText}
          confirmDeleteMessage={questions.additionalDrivers.confirmDeleteText}
          allowZeroSections
          alignLeft
          confirmCallbacks={{
            onOpen: () => trackModalOpen('removeAdditionalDriver'),
            onClose: () => trackButtonClick('closeButton', 'Close'),
            onYesClick: () => trackButtonClick('removeAdditionalDriver', 'yes'),
            onNoClick: () => trackButtonClick('removeAdditionalDriver', 'no'),
          }}>
          {additionalDriversDetails.additionalDrivers.map((driver, index) => (
            <AdditionalDriverForm
              key={driver.key}
              idPrefix={getAdditionalDriverIdPrefix(index)}
              additionalDriver={driver}
              updateAdditionalDriver={(update) =>
                updateAdditionalDriversDetails({
                  additionalDrivers: updateItem(
                    additionalDriversDetails.additionalDrivers,
                    index,
                    update
                  ),
                })
              }
              getError={(id, ...nested) =>
                getError('additionalDrivers', [id, index], ...nested)
              }
              showValidation={(id, ...nested) =>
                showValidation('additionalDrivers', [id, index], ...nested)
              }
            />
          ))}
        </RepeatableSection>
      )}
      {additionalDriversDetails.addAdditionalDrivers && (
        <QuestionField question={questions.mainDriver} errorText={getError('mainDriver')}>
          <SelectInput
            id="mainDriver"
            options={getMainDriverOptions()}
            value={additionalDriversDetails.mainDriver?.toString() ?? ''}
            placeholder={questions.mainDriver.placeholder}
            onChange={(event) => {
              trackDropdownSelect('mainDriver', event.target.value);
              const { value } = event.target;
              updateAdditionalDriversDetails({
                mainDriver:
                  value === MAIN_DRIVER_POLICY_HOLDER_VALUE ? value : Number(value),
              });
            }}
            onBlur={() => showValidation('mainDriver')}
            onFocus={trackSelectOrTypeaheadFocus('mainDriver')}
          />
        </QuestionField>
      )}
      <InfoBanner alignLeft>
        <InfoBannerHeading>{infoBanner.heading}</InfoBannerHeading>
        <RichTextWithModal html={infoBanner.body} backgroundColor={colors.neutral07} />
      </InfoBanner>
      <FormFooter
        contentColumns={{ desktop: 10 }}
        moveNextButton={{
          text: moveNextText,
        }}
        backButton={{
          onClick: moveBack,
        }}
      />
    </form>
  );
};

export default AdditionalDriversForm;
