import { TableRow } from '@rsa-digital/evo-shared-components/components/ExpandableTable';
import { Dispatch } from 'redux';
import { AdditionalDriverDetailLabels } from 'components/PolicyDetails/AdditionalDriverDetails/labels';
import { CarDetailLabels } from 'components/PolicyDetails/CarDetails/labels';
import { PolicyholderDetailLabels } from 'components/PolicyDetails/PolicyholderDetails/labels';
import {
  AdditionalDriversDetails,
  AdditionalDriverWithKey,
  UPDATE_ADDITIONAL_DRIVERS_DETAILS,
} from 'state/formData/additionalDriversDetails';
import { UPDATE_DRIVER_DETAILS } from 'state/formData/driverDetails';
import { UPDATE_VEHICLE_DETAILS } from 'state/formData/vehicleDetails';
import {
  MigratedAdditionalDriverDetailsFlags,
  MigratedPolicyHolderFieldsFlags,
  MigratedVehicleDetailsFlags,
  Policy,
} from 'state/policy/policy';
import { Quote } from 'state/quote/quote';

export const isPolicy = (policy: Quote | Policy): policy is Policy =>
  !!(policy as Policy).policyNumber;

export const isMigratedPolicy = (policy: Quote | Policy): boolean => {
  return !!(policy as Policy).isMigrated;
};

// For fields which are "migrated" or "assumed", the API returns a policyIsMigrated flag, and sets the field to null
// We should not display migrated fields to the user, so remove them
const removeFromTableRow = (title: string, rows: TableRow[]): TableRow[] => {
  const index = rows.findIndex((row) => row.title === title);
  rows.splice(index, index >= 0 ? 1 : 0);
  return rows;
};

export const removeMigratedValuesFromPolicyHolder = (
  rows: TableRow[],
  labels: PolicyholderDetailLabels,
  flags: MigratedPolicyHolderFieldsFlags
): TableRow[] => {
  if (flags?.livedInUkSinceBirthIndicatorIsMigrated) {
    removeFromTableRow(labels.livedInUkSince, rows);
  }
  if (flags?.ukResidentSinceIsMigrated) {
    removeFromTableRow(labels.livedInUkSince, rows);
  }
  if (flags?.useOfOtherVehiclesIsMigrated) {
    removeFromTableRow(labels.otherVehicleUse, rows);
  }
  if (flags?.licenceTypeIsMigrated) {
    removeFromTableRow(labels.drivingLicenceType, rows);
  }
  if (flags?.maritalStatusIsMigrated) {
    removeFromTableRow(labels.maritalStatus, rows);
  }
  if (flags?.isHomeOwnerIsMigrated) {
    removeFromTableRow(labels.isHomeowner, rows);
  }
  if (flags?.childrenUnder16IndicatorIsMigrated) {
    removeFromTableRow(labels.numberOfChildren, rows);
  }
  if (flags?.numberOfChildrenInHomeUnder16IsMigrated) {
    removeFromTableRow(labels.numberOfChildren, rows);
  }

  return rows;
};

export const removeMigratedValuesFromVehicle = (
  rows: TableRow[],
  labels: CarDetailLabels,
  flags: MigratedVehicleDetailsFlags
): TableRow[] => {
  if (flags?.isRegisteredKeeperIsMigrated) {
    removeFromTableRow(labels.registeredKeeper, rows);
  }
  if (flags?.registeredKeeperOfVehicleIsMigrated) {
    removeFromTableRow(labels.registeredKeeper, rows);
  }
  if (flags?.vehicleKeptOvernightIsMigrated) {
    removeFromTableRow(labels.nighttimeLocation, rows);
  }
  if (flags?.postcodeOfVehicleOvernightIsMigrated) {
    removeFromTableRow(labels.nighttimeLocation, rows);
  }
  if (flags?.carKeptDuringDayIsMigrated) {
    removeFromTableRow(labels.daytimeLocation, rows);
  }

  return rows;
};

export const removeMigratedValuesFromAdditionalDriver = (
  rows: TableRow[],
  labels: AdditionalDriverDetailLabels,
  flags: MigratedAdditionalDriverDetailsFlags
): TableRow[] => {
  if (flags?.livedInUkSinceBirthIndicatorIsMigrated) {
    removeFromTableRow(labels.livedInUkSince, rows);
  }
  if (flags?.ukResidentSinceIsMigrated) {
    removeFromTableRow(labels.livedInUkSince, rows);
  }
  if (flags?.useOfOtherVehiclesIsMigrated) {
    removeFromTableRow(labels.otherVehicleUse, rows);
  }
  if (flags?.licenceTypeIsMigrated) {
    removeFromTableRow(labels.drivingLicenceType, rows);
  }
  if (flags?.maritalStatusIsMigrated) {
    removeFromTableRow(labels.maritalStatus, rows);
  }
  if (flags?.relationshipToPolicyHolderIsMigrated) {
    removeFromTableRow(labels.relationshipToPolicyholder, rows);
  }
  return rows;
};

export const getMigratedAdditionalDriverDetailsFlags = (
  driverId: string,
  migratedAdditionalDriverFields: MigratedAdditionalDriverDetailsFlags[] | undefined
): MigratedAdditionalDriverDetailsFlags | undefined => {
  return (
    migratedAdditionalDriverFields?.find((d) => d.driverId === driverId) ?? undefined
  );
};

const mergeAdditionalDriversWithMigratedFields = (
  additionalDrivers: AdditionalDriverWithKey[],
  migratedFields: MigratedAdditionalDriverDetailsFlags[] | undefined
): AdditionalDriverWithKey[] => {
  if (!migratedFields) {
    return additionalDrivers;
  }
  return additionalDrivers.map((driver) => ({
    ...driver,
    migratedFields: getMigratedAdditionalDriverDetailsFlags(
      driver.driverId || '',
      migratedFields
    ),
  }));
};

export const SetupMigratedFieldsForRenewal = (
  policy: Policy,
  additionalDriverDetails: AdditionalDriversDetails,
  dispatch: Dispatch
): void => {
  dispatch({
    type: UPDATE_DRIVER_DETAILS,
    update: {
      migratedFields: policy.migratedFields?.policyHolder,
    },
  });
  dispatch({
    type: UPDATE_VEHICLE_DETAILS,
    update: {
      migratedFields: {
        ...policy.migratedFields?.vehicleDetails,
        annualBusinessMileageIsMigrated:
          policy.migratedFields?.policyHolder?.annualBusinessMileageIsMigrated,
      },
    },
  });
  dispatch({
    type: UPDATE_ADDITIONAL_DRIVERS_DETAILS,
    update: {
      ...additionalDriverDetails,
      additionalDrivers: mergeAdditionalDriversWithMigratedFields(
        additionalDriverDetails?.additionalDrivers,
        policy.migratedFields?.additionalDrivers
      ),
    },
  });
};
