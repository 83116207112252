import { PolicyDocuments } from 'components/MyPolicy/DocumentDownloadSection';
import { Policy } from 'state/policy/policy';
import apiService from './apiService';

export type PolicyClient = {
  getPolicy: (policyNumber: string) => Promise<Policy>;
  getDocuments: (policyNumber: string) => Promise<PolicyDocuments[]>;
  downloadDocument: (policyNumber: string, documentId: string) => Promise<void>;
};

const policyClient: PolicyClient = {
  getPolicy: async (policyNumber: string) => apiService.get(`policy/${policyNumber}`),
  getDocuments: async (policyNumber: string) =>
    apiService.get(`policy/${policyNumber}/documents`),
  downloadDocument: async (policyNumber: string, documentId: string) =>
    apiService.get(`policy/${policyNumber}/documents/${documentId}`),
};

export default policyClient;
