import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const InfoBannerHeading = styled.h2`
  ${fonts.headingSmall}
  margin: 0;
  padding-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    padding-bottom: ${spacing(2)};
  `}
`;
